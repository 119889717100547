import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';

@Component({
    selector: 'app-checkmark-circle-icon',
    imports: [CommonModule],
    templateUrl: './checkmark-circle-icon.component.html',
    styles: []
})
export class CheckmarkCircleIconComponent {}
