import { CdkMenuModule } from '@angular/cdk/menu';
import { CurrencyPipe, NgClass, TitleCasePipe } from '@angular/common';
import {
    ChangeDetectionStrategy,
    Component,
    computed,
    inject,
    input,
    OnInit,
    signal,
} from '@angular/core';
import { MatTooltipModule } from '@angular/material/tooltip';
import TEXT from '@content/settings.en.json';
import { SUB_STATUS } from '@core/models/admin/admin-account.types';
import { BtnSimpleComponent } from '@shared/components/btn-simple/btn-simple.component';
import { DropdownOptSingleComponent } from '@shared/components/dropdown-opt-single/dropdown-opt-single.component';
import { DropdownComponent } from '@shared/components/dropdown/dropdown.component';
import { CheckmarkCircleIconComponent } from '@shared/components/icons/checkmark-circle-icon/checkmark-circle-icon.component';
import { InfoIconComponent } from '@shared/components/icons/info-icon/info-icon.component';
import { SubscribePlanIconComponent } from '@shared/components/icons/subscribe-plan-icon/subscribe-plan-icon.component';
import {
    BILLING_CURRENCY_CODE,
    BILLING_PLAN_RECURRING,
    BILLING_PLAN_TYPE,
    BillingPlanDTO,
    BillingPlanRecurring,
} from '../../billing.types';
import { BillingService } from '../../services/billing.service';
import {
    callToActionByBillingTypes,
    callToActionByBillingTypesInModal,
    featuresByBillingTypes,
    TOOLTIP_CLASSES,
} from './pricing-plan-card.config';

const components = [
    SubscribePlanIconComponent,
    CheckmarkCircleIconComponent,
    BtnSimpleComponent,
    InfoIconComponent,
    DropdownComponent,
    DropdownOptSingleComponent,
];
@Component({
    selector: 'app-pricing-plan-card',
    imports: [...components, CurrencyPipe, CdkMenuModule, MatTooltipModule, NgClass, TitleCasePipe],
    templateUrl: './pricing-plan-card.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PricingPlanCardComponent implements OnInit {
    private readonly billingS = inject(BillingService);
    readonly TEXT = TEXT;
    readonly BILLING_PLAN_TYPE = BILLING_PLAN_TYPE;
    readonly BILLING_PLAN_RECURRING = BILLING_PLAN_RECURRING;
    readonly tooltipClasses = TOOLTIP_CLASSES;
    readonly BILLING_CURRENCY_CODE = BILLING_CURRENCY_CODE;

    readonly recurring = input.required<BillingPlanRecurring>();
    readonly plans = input.required<BillingPlanDTO[]>();

    readonly topRounded = input<boolean>(true);
    readonly isInModal = input<boolean>(false);

    readonly selectedPlan = signal<BillingPlanDTO | undefined>(undefined);

    readonly variant = computed(() => this.computeInitialVariant());
    readonly priceByMonthWithoutDiscount = computed(() =>
        this.computePriceByMonthWithoutDiscount()
    );
    readonly selectedPriceByYear = computed(() => this.computePriceByRecurring());
    readonly selectedPriceByMonth = computed(() => this.computePriceByMonth());
    readonly features = computed(() => featuresByBillingTypes[this.variant()]);
    readonly callToAction = computed(() => this.computeCallToAction());

    readonly subStatus = this.billingS.subStatus;

    ngOnInit(): void {
        this.selectedPlan.set(this.plans()[0]);
    }

    handleSubscribe() {
        const selectedPlan = this.selectedPlan();
        const priceId = selectedPlan?.prices?.find(p => p.recurring === this.recurring())?.price_id;
        if (!priceId) throw new Error('Product id is not defined');
        const variant = selectedPlan.type;
        const hasTrial = this.subStatus() === SUB_STATUS.TRIAL;

        switch (variant) {
            case BILLING_PLAN_TYPE.starter:
                this.billingS.subscribePlan(priceId);
                break;
            case BILLING_PLAN_TYPE.growth:
                if (hasTrial) {
                    this.billingS.subscribePlan(priceId);
                } else {
                    this.billingS.upgradePlan(priceId);
                }
                break;
            case BILLING_PLAN_TYPE.custom:
                this.billingS.goToContactUs();
                break;
        }
    }

    handlePlanSelect(plan: BillingPlanDTO) {
        this.selectedPlan.set(plan);
    }

    private computeCallToAction() {
        const planType = this.selectedPlan()?.type ?? BILLING_PLAN_TYPE.starter;
        return this.isInModal()
            ? callToActionByBillingTypesInModal[planType]
            : callToActionByBillingTypes[planType];
    }

    private computeInitialVariant() {
        return this.plans()[0]?.type ?? BILLING_PLAN_TYPE.starter;
    }
    private computePriceByMonth() {
        const isYearly = this.recurring() === BILLING_PLAN_RECURRING.year;
        return isYearly ? this.computePriceByRecurring() / 12 : this.computePriceByRecurring();
    }

    private computePriceByRecurring() {
        const prices = this.selectedPlan()?.prices;
        const recurring = this.recurring();
        return prices?.find(p => p.recurring === recurring)?.price ?? 0;
    }

    private computePriceByMonthWithoutDiscount() {
        const isYearly = this.recurring() === BILLING_PLAN_RECURRING.year;
        const prices = this.selectedPlan()?.prices;
        const priceByMonth =
            prices?.find(p => p.recurring === BILLING_PLAN_RECURRING.month)?.price ?? 0;
        return isYearly ? priceByMonth : null;
    }
}
