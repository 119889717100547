<article
    [ngClass]="{ 'rounded-t-none': !topRounded() }"
    class="card-wrapper flex w-full justify-between pb-[50px] pl-[70px] pr-[48px] pt-[45px]">
    <div class="flex w-[261px]">
        <app-subscribe-plan-icon class="block size-[40px] min-w-[40px] text-twGray" />
        <div class="flex flex-col items-start justify-between">
            <div>
                <span class="text-2xl font-medium text-twElectricPurple">
                    {{ variant() }}
                </span>
                @if (variant() === BILLING_PLAN_TYPE.growth) {
                    <button
                        class="relative mb-[9px] mt-[5px] flex"
                        matTooltip="{{ TEXT.billing.macTooltip }}"
                        matTooltipClass="{{ tooltipClasses }}"
                        matTooltipPosition="right">
                        <span class="text-xs font-medium italic">
                            {{ TEXT.billing.monthlyActiveUsers }}
                        </span>
                        <app-info-icon class="block size-[15px] text-twMidPurple" />
                    </button>

                    <app-dropdown
                        [cdkMenuTriggerFor]="macsMenu"
                        class="block h-[29px] w-[114px]"
                        [selectedValue]="selectedPlan()?.macs?.toString()">
                        <ng-template #macsMenu>
                            <div cdkMenu class="dropdown-menu max-h-[40vh] w-[114px] py-2">
                                @for (plan of plans(); track $index) {
                                    <button cdkMenuItem (click)="handlePlanSelect(plan)">
                                        <app-dropdown-opt-single>
                                            <span class="text-s">{{ plan.macs }}</span>
                                        </app-dropdown-opt-single>
                                    </button>
                                }
                            </div>
                        </ng-template>
                    </app-dropdown>
                }
            </div>

            <div class="flex w-[221px] items-end">
                @if (selectedPlan()?.type === BILLING_PLAN_TYPE.custom) {
                    <span class="text-3xl font-medium"> {{ TEXT.billing.talkToSales }}</span>
                } @else {
                    @if (priceByMonthWithoutDiscount()) {
                        <span class="mr-2 text-2xl text-twGrayLight line-through">
                            {{
                                priceByMonthWithoutDiscount()
                                    | currency: BILLING_CURRENCY_CODE : 'symbol' : '1.0-0'
                            }}
                        </span>
                    }
                    <span class="mr-1 text-3xl font-medium">
                        {{
                            selectedPriceByMonth()
                                | currency: BILLING_CURRENCY_CODE : 'symbol' : '1.0-0'
                        }}
                    </span>
                    <span class="pb-1 text-xs text-twGrayDark">/ {{ TEXT.billing.month }}</span>
                }
            </div>
        </div>
    </div>

    <div class="flex w-[438px] flex-col gap-4 text-twGrayDark">
        <h4>{{ TEXT.billing.featureIncluded }}</h4>
        <ul class="flex flex-col gap-3">
            @for (feature of features(); track $index) {
                <li class="flex gap-3">
                    <app-checkmark-circle-icon class="block size-[15px] text-twElectricPurple" />
                    <span>{{ feature }}</span>
                </li>
            }
        </ul>
    </div>
    <div class="flex w-[237px] flex-col justify-center">
        @if (recurring() === BILLING_PLAN_RECURRING.year) {
            <span
                class="text-xxs mb-2 w-[217px] text-right font-medium italic text-twErrorRed underline">
                {{ selectedPriceByYear() }}
                {{ BILLING_CURRENCY_CODE }}
                {{ TEXT.billing.billedAnnually }}
            </span>
        }
        <app-btn-simple class="w-[217px]" [w]="'full'" (btnClick)="handleSubscribe()">
            {{ callToAction() | titlecase }}
        </app-btn-simple>
        @if (
            variant() === BILLING_PLAN_TYPE.growth &&
            selectedPlan()?.type !== BILLING_PLAN_TYPE.custom
        ) {
            <button
                class="relative flex items-center"
                matTooltip="{{ TEXT.billing.additionalMacTooltip }}"
                matTooltipClass="{{ tooltipClasses }}"
                matTooltipPosition="below">
                <app-info-icon class="block size-[18px] text-twMidPurple" />
                <span class="text-xxs text-nowrap italic text-twTextPrimary">
                    {{ TEXT.billing.additionalUsage }}
                </span>
            </button>
        }
    </div>
</article>
