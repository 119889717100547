<svg
    [ngStyle]="{ fill: 'currentColor', height: 'inherit' }"
    width="25"
    height="25"
    viewBox="0 0 25 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
        d="M12.333 0.333984C5.70901 0.333984 0.333008 5.70998 0.333008 12.334C0.333008 18.958 5.70901 24.334 12.333 24.334C18.957 24.334 24.333 18.958 24.333 12.334C24.333 5.70998 18.957 0.333984 12.333 0.333984ZM9.93301 18.334L3.93301 12.334L5.62501 10.642L9.93301 14.938L19.041 5.82998L20.733 7.53398L9.93301 18.334Z" />
</svg>
